@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        /* --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0; */
        --foreground-rgb: 0, 0, 0;
        --background-start-rgb: 214, 219, 220;
        --background-end-rgb: 255, 255, 255;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

@layer components {
    .main-container {
        @apply max-w-screen-xl mx-auto px-4;
    }

    .main-title {
        @apply text-5xl font-bold leading-[60px] text-gray-800;
    }

    .main-title-mobile {
        @apply text-2xl font-bold text-gray-700 leading-[34px];
    }
    .title-1 {
        @apply text-4xl font-semibold text-gray-800;
    }

    .title-1-mobile {
        @apply text-2xl font-semibold text-gray-800;
    }

    .services-title {
        @apply text-4xl font-semibold text-gray-800 relative;
    }

    .services-title::after {
        position: absolute;
        content: url('/public/assets/images/slider/services-title-icon.svg');
        top: -20px;
        right: -20px;
        display: block;
        width: 24.354px;
        height: 32.868px;
    }
    .subtitle-1 {
        @apply text-xl font-semibold leading-7 text-gray-800;
    }

    .subtitle-2 {
        @apply text-lg font-semibold leading-5 text-gray-800;
    }

    .subtitle-3 {
        @apply text-sm font-semibold leading-[18px] text-gray-800;
    }

    .footer-title {
        @apply text-sm font-semibold;
    }
    .footer-item {
        @apply text-sm font-medium hover:text-primary cursor-pointer transition-all duration-150;
    }

    /* slider */

    .shadow-small {
        box-shadow: 0 4px 15px rgba(58, 112, 191, 0.1);
    }

    .shadow-1 {
        box-shadow: 0 10px 70px 0 rgba(58, 112, 191, 0.16);
    }
    .drop-shadow-1 {
        filter: drop-shadow(-17px 47px 70px rgb(202, 215, 233));
    }

    .z-dropdown {
        z-index: 999;
    }
}

@layer base {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.hasImage:hover section {
    background-color: rgba(5, 5, 5, 0.4);
}
.hasImage:hover button:hover {
    background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
    opacity: 0;
}

#overlay.draggedover {
    background-color: rgba(255, 255, 255, 0.7);
}
#overlay.draggedover p,
#overlay.draggedover i {
    opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
    color: #2b6cb0;
}

.subpixel-antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: antialiased !important;
}
